* {
  font-family: 'Luckiest Guy', cursive;
}

body {
  margin: 0;
  padding: 5px;
  background-color: rgb(255, 225, 205);
}

//TOP CONTAINER

.top-container {
  margin: 20px auto;
  display: flex;
  width: 37%;

  .main-logo-text {
    margin: 0 auto;
    align-self: center;
    font-size: 3.2rem;
    padding: 1rem;
    color: red;
  }

  img {
    margin: 0 auto;
    border-radius: 50%;
    transition: all 1s ease-in-out;
    width: 200px;

    &:hover {
      transform: rotate(360deg);
    }
  }

  nav {
    ul {
      li {
        list-style-type: none;
        margin: 5px auto;
        background: rgb(77, 73, 73);
        text-align: center;
        border-radius: 7px;
        padding: 5px;

        &:hover {
          transform: scale(1.06);
        }

        a {
          transition: all 0.2s ease-in-out;
          letter-spacing: 1px;
          text-decoration: none;
          color: red;

          &:hover {
            transform: scale(1.02);
            color: yellow;
          }
        }
      }
    }
  }
}