.outer-container {
  width: 100%;
}
.outer-container h1 {
  margin: 35px auto;
  letter-spacing: 2px;
  background-color: rgb(81, 77, 77);
  text-align: center;
  border: 2px solid white;
  border-radius: 4px;
  color: white;
  font-weight: bolder;
  padding: 1rem;
}

.pokemon-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
}/*# sourceMappingURL=pokemon_container.css.map */