.auth-container {
    letter-spacing: 1px;
    display: flex;
    margin: 5% auto;
    justify-content: space-between;
    align-items: center;
    width: 43%;

    input {
        font-size: larger;
        padding: 2rem 5px;
        border-radius: 5px;
    }

    button {
        padding: 2rem;
        border-radius: 50%;
        border: 3px solid black;
        background-color: red;
        color: yellow;
        font-size: larger;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        &:hover{
            transform: scale(1.1);
            color: black;
            border-color: yellow;
        }
    }
  }