.pokemon-card {
  width: 220px;
  height: 350px;
  border: 3px solid rgb(216, 90, 90);
  background: url("../assets/yyel.jpg");
  border-radius: 5px;
  padding: 15px;
  overflow: hidden;
  margin: 20px 0;
}
.pokemon-card .pokemon-details {
  display: flex;
  flex-direction: column;
  height: 30%;
}
.pokemon-card .pokemon-details img {
  background: white;
  border: 2px solid black;
  border-radius: 50%;
  padding: 5px;
  width: 70%;
  margin: 10px auto;
}
.pokemon-card .pokemon-details h3 {
  color: red;
  margin: 5px auto;
  background: rgb(77, 73, 73);
  width: 70%;
  text-align: center;
  border-radius: 7px;
  padding: 3px;
  letter-spacing: 1px;
}
.pokemon-card .pokemon-details p {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin: 2px 0;
  background: white;
  padding: 3px;
  letter-spacing: 1px;
  border: 2px solid black;
  border-radius: 2px;
}
.pokemon-card .pokemon-details .abilities {
  font-size: 12px;
}
.pokemon-card .pokemon-details .add-pokemon-button {
  padding: 0.5rem;
  margin: 1rem auto;
  width: 120px;
  background-color: yellow;
  border-color: red;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.pokemon-card .pokemon-details .add-pokemon-button:hover {
  border-color: black;
  color: red;
  transform: scale(1.06);
}/*# sourceMappingURL=pokeItem.css.map */